<template>
  <div>
    <div class="c_left">
      <div><img src="../assets/images/img_19.png" alt="" /></div>
      <div class="marc20 font18 color131">{{ user.user_name }}</div>
      <div class="marc20 font14 colorb6">{{ user.name }}</div>
      <div
        class="c_left_btn"
        :class="curMenu == inx ? 'color-ffa weight mcl-ac' : ''"
        v-for="(item, inx) in menu"
        :key="inx"
        @click="btn((curMenu = inx), item.path)"
      >
        <div class="aa hand">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user:{},
      curMenu: "",
      menu: [
        { name: "我的信息", path: "/Personal/myInfo" },
        { name: "购买课程", path: "/Personal/Purchase" },
        { name: "学习记录", path: "/Personal/Study" },
        { name: "消息中心", path: "/Personal/Information" },
      ],
    };
  },
  mounted() {
    let user = localStorage.getItem("userInfo");
    this.user = JSON.parse(user);
  },
  methods: {
    btn(inx, path) {
      this.getNav = inx;
      this.$router.push({ path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.view {
  display: flex;
  .c_left {
    background: #f8f8f8;
    flex: 1;
    text-align: center;
    padding: 40px 0;
    .c_left_btn {
      line-height: 70px;
      &:hover .aa {
        background: #ffffff;
      }
      .mcl-ac::after {
        content: "";
        width: 40px;
        height: 4px;
        // background: #ff9905;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
<template>
  <div class="main p-t-20 p-b-20">
    <div class="w1200">
      <div class="padc16 font14 color999 flex-left">
        <img src="@/assets/images/icon_27.png" alt="" />
        <span class="m-l-8">当前位置</span>
        <span class="pad8">/</span>
        首页
        <span class="pad8">/</span>
        <span class="colorff9">个人中心</span>
      </div>
      <div class="container m_con">
        <div class="view flex">
          <div class="c_left">
            <LeftNav> </LeftNav>
          </div>
          <div class="c_right">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "../views/LeftNav";
export default {
  data() {
    return {};
  },
  components: {
    LeftNav,
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.main {
  background-color: #f8f8f8;
  .m_con {
    box-shadow: 0px 2px 6px 0px rgba(171, 171, 171, 0.3);
    border-radius: 20px;
    background-color: #fff;
  }
  .c_left {
    flex: 1;
  }
  .c_right {
    flex: 4;
  }
}
</style>